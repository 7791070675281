import React, { useEffect } from 'react';
import { useState } from 'react';
import './Troubleshoot-styles.scss';
import '../app/styles.scss';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

type TroubleshootProps = {
  lang: 'en' | 'id';
};

type troubleShootItemType = {
  title: string;
  problem: string;
  potentialCause: string | string[];
  solution: string | string[];
  isOpened: boolean;
};

type TroubleshootType = {
  savart: troubleShootItemType[];
  battery: troubleShootItemType[];
  iotConnection: troubleShootItemType[];
  portableCharger: troubleShootItemType[];
};

type TroubleshootKey = keyof TroubleshootType;

const enTroubleshoots = {
  savart: [
    {
      title: 'Savart',
      problem: 'The electric scooter will not start or operate.',
      potentialCause: 'Potential Cause: The battery is dead or not charged.',
      solution: 'Solution: Charge the battery.',
      isOpened: false,
    },
    {
      title: 'Savart',
      problem: 'The scooter suddenly shuts down, and "SAFETY STOP" appears on the Cluster Display/HMI.',
      potentialCause:
        'Potential Cause: Overheating/Error (a "WARNING" followed by an "ERROR" has already appeared on the Cluster Display/HMI).',
      solution:
        'Solution: Check the type of error that occurred (you can see the text error before the scooter shut down, or a notification will appear in the app if an error occurs).',
      isOpened: false,
    },
    {
      title: 'Savart',
      problem: 'Unstable steering handlebars.',
      potentialCause: [
        'Incorrect tire pressure.',
        'Worn/bald tires.',
        'Damaged steering bearings.',
        'Damaged wheel bearings.',
        'Worn engine mounts that connect the engine to the chassis.',
        'Faulty suspension.',
        'Loose wheel axle.',
      ],
      solution: [
        'Solution: Inflate the tires to the recommended pressure (check the manual for recommended pressure).',
        'Solution: Replace the tires with those that meet the recommended specifications.',
        'Solution: Inspect the bearing components. Replace any worn/damaged bearings. Tighten the handlebars properly.',
        'Solution: Inspect the bearing components. Replace any worn/damaged bearings.',
        'Solution: Replace the bushings.',
        'Solution: Replace the suspension.',
        'Solution: Tighten the wheel axle.',
      ],
      isOpened: false,
    },
    {
      title: 'Savart',
      problem: 'WARNING: Motor Overtemp (text appears on the Cluster Display/HMI)',
      potentialCause: 'Potential Cause: Motor temperature is nearing too hot/high (approaching overheat).',
      solution: 'Solution: It is recommended to stop briefly to allow cooling.',
      isOpened: false,
    },
  ],
  battery: [
    {
      title: 'Battery',
      problem: 'WARNING: Battery Overtemp (text appears on the Cluster Display/HMI)',
      potentialCause: 'Potential Cause: Battery temperature is nearing too hot/high (approaching overheat).',
      solution: 'Solution: It is recommended to stop briefly to allow cooling.',
      isOpened: false,
    },
    {
      title: 'Battery',
      problem: 'ERROR: Battery Error and Overcurrent (text appears on the Cluster Display/HMI)',
      potentialCause: 'Potential Cause: Occurs when the electric current flowing exceeds the safe limit.',
      solution:
        'Solution: Turn off the scooter, disconnect the battery connector, wait for 10 minutes, reconnect the battery connector, then turn on the scooter.',
      isOpened: false,
    },
    {
      title: 'Battery',
      problem: 'ERROR: Battery Overheat (text appears on the Cluster Display/HMI)',
      potentialCause: 'Potential Cause: Battery temperature is too high.',
      solution: 'Solution: Stop and turn off the scooter to allow cooling for 10 minutes, then turn on the scooter.',
      isOpened: false,
    },
  ],
  iotConnection: [
    {
      title: 'IoT Connection',
      problem: 'WARNING: Controller Overtemp (text appears on the Cluster Display/HMI)',
      potentialCause: 'Potential Cause: The controller temperature is nearing too hot/high (approaching overheat).',
      solution: 'Solution: It is recommended to stop briefly to allow cooling.',
      isOpened: false,
    },
    {
      title: 'IoT Connection',
      problem: 'ERROR: Communication Error (text appears on the Cluster Display/HMI)',
      potentialCause: 'Potential Cause: There is an error in communication.',
      solution:
        'Solution: Turn off the scooter, disconnect the battery connector, wait for 1 minute, reconnect the battery connector, then restart the scooter.',
      isOpened: false,
    },
    {
      title: 'IoT Connection',
      problem: 'ERROR: Controller Error (text appears on the Cluster Display/HMI)',
      potentialCause: 'Potential Cause: There is an error in the controller.',
      solution: 'Solution: Turn off the scooter, wait for 5 minutes, then restart the scooter.',
      isOpened: false,
    },
    {
      title: 'IoT Connection',
      problem: 'ERROR: Controller Overheat (text appears on the Cluster Display/HMI)',
      potentialCause: 'Potential Cause: The controller temperature is too high.',
      solution:
        'Solution: Stop and power off the scooter to allow it to cool down for 10 minutes, then restart the scooter.',
      isOpened: false,
    },
  ],
  portableCharger: [
    {
      title: 'Portable Charger',
      problem: 'The Charger cannot charge the battery.',
      potentialCause: ['No voltage at the power source outlet.', 'The charger is damaged.', 'The battery is too hot.'],
      solution: [
        'Solution: Check the voltage at the power source outlet.',
        'Solution: Bring the charger to After-Sales Service.',
        'Solution: Allow the battery to cool down.',
      ],
      isOpened: false,
    },
    {
      title: 'Portable Charger Title',
      problem: 'Communication Failure:\nRed-red-green',
      potentialCause:
        'Potential Cause: There is a problem in the communication process between the charger and the charged device.',
      solution:
        'Solution: Ensure that the charging procedure is followed. Try unplugging and reconnecting the charger and the electric motor and socket.',
      isOpened: false,
    },
    {
      title: 'Portable Charger',
      problem: 'Input Exception:\nRed-green-green',
      potentialCause:
        'Potential Cause: An error or problem that occurs when the charger receives input from an external power source, such as a power outlet.',
      solution:
        'Solution: Ensure that the power source of the house meets the standard (± 220 vac, 50 Hz). If so, repeat the charging procedure.',
      isOpened: false,
    },
    {
      title: 'Portable Charger',
      problem: 'Output Overvoltage:\nRed-green-green-green',
      potentialCause:
        'Potential Cause: The charger outputs a voltage that exceeds the predetermined safe limit for the battery or electric motor system.',
      solution: 'Solution: Repeat the charging procedure.',
      isOpened: false,
    },
    {
      title: 'Portable Charger',
      problem: 'Superheating:\nRed-red-green-green',
      potentialCause: 'Potential Cause: The charger temperature is too hot/high.',
      solution:
        'Solution: The charger will stop momentarily. However, if the temperature has normalized, the charging process will automatically restart.',
      isOpened: false,
    },
  ],
};

const idTroubleshoots = {
  savart: [
    {
      title: 'Savart',
      problem: 'Skuter listrik tidak dapat hidup atau beroperasi',
      potentialCause: 'Potensi Penyebab: Baterai habis, tidak terisi daya.',
      solution: 'Solusi: Lakukan pengisian daya baterai.',
      isOpened: false,
    },
    {
      title: 'Savart',
      problem: 'Skuter tiba tiba mati, muncul “SAFETY STOP” pada Cluster Display/HMI',
      potentialCause:
        'Potensi Penyebab: Overheat/Error (sudah muncul peringatan “WARNING” disusul “ERROR” pada Cluster Display/HMI).',
      solution:
        'Solusi: Cek jenis error yang terjadi (bisa text error sebelum skuter mati, atau di apps akan muncul notifikasi apabila terjadi error).',
      isOpened: false,
    },
    {
      title: 'Savart',
      problem: 'Stang kemudi tidak stabil',
      potentialCause: [
        'Tekanan angin ban tidak sesuai.',
        'Ban telah using/gundul.',
        'Bearing kemudi mengalami kerusakan.',
        'Bearing velg/roda mengalami kerusakan.',
        'Engine mounting yang menjadi penyambung mesin dan chassis sudah aus.',
        'Suspensi mati.',
        'As roda longgar.',
      ],
      solution: [
        'Solusi: Lakukan pengisian angin ban sesuai rekomendasi pabrik (cek rekomendasi tekanan pada buku manual).',
        'Solusi: Lakukan penggantian ban sesuai dengan spesifikasi yang dianjurkan.',
        'Solusi: Periksa komponen bearing. Ganti komponen yang sudah aus/rusak. Kencangkan stang kemudi dengan baik.',
        'Solusi: Periksa komponen bearing. Ganti komponen yang sudah aus/rusak.',
        'Solusi: Lakukan penggantian busing.',
        'Solusi: Lakukan penggantian suspensi.',
        'Solusi: kencangkan as roda.',
      ],
      isOpened: false,
    },
    {
      title: 'Savart',
      problem: 'WARNING: Motor Overtemp (muncul text pada Cluster Display/HMI)',
      potentialCause: 'Potensi Penyebab: Suhu motor hampir terlalu panas/tinggi (hampir overheat).',
      solution: 'Solusi: Disarankan berhenti sejenak untuk mendinginkan.',
      isOpened: false,
    },
  ],
  battery: [
    {
      title: 'Baterai',
      problem: 'WARNING: Battery Overtemp (muncul text pada Cluster Display/HMI)',
      potentialCause: 'Potensi Penyebab: Suhu baterai hampir terlalu panas/tinggi (hampir overheat)',
      solution: 'Solusi: Disarankan berhenti sejenak untuk mendinginkan.',
      isOpened: false,
    },
    {
      title: 'Baterai',
      problem: 'ERROR: Battery Error and Overcurrent (muncul text pada Cluster Display/HMI)',
      potentialCause: 'Potensi Penyebab: Terjadi ketika arus listrik yang mengalir melebihi batras aman.',
      solution:
        'Solusi: Matikan skuter, cabut konektor baterai, tunggu 10 menit, pasang konektor baterai, nyalakan skuter.',
      isOpened: false,
    },
    {
      title: 'Baterai',
      problem: 'ERROR: Battery Overheat (text appears on the Cluster Display/HMI)',
      potentialCause: 'Potential Cause: Battery temperature is too high.',
      solution: 'Solution: Stop and turn off the scooter to allow cooling for 10 minutes, then turn on the scooter.',
      isOpened: false,
    },
  ],
  iotConnection: [
    {
      title: 'Koneksi IoT',
      problem: 'WARNING: Controller Overtemp (muncul text pada Cluster Display/HMI)',
      potentialCause: 'Potensi Penyebab: Suhu controller hampir terlalu panas/tinggi (hampir overheat)',
      solution: 'Solusi: Disarankan untuk berhenti sejenak untuk mendinginkan.',
      isOpened: false,
    },
    {
      title: 'Koneksi IoT',
      problem: 'ERROR: Communication Error (muncul text pada Cluster Display/HMI)',
      potentialCause: 'Potensi Penyebab: Terjadi error pada communication.',
      solution:
        'Solusi: Matikan skuter, cabut konektor baterai, tunggu 1 menit, pasang konektor baterai, nyalakan skuter kembali.',
      isOpened: false,
    },
    {
      title: 'Koneksi IoT',
      problem: 'ERROR: Controller Error (muncul text pada Cluster Display/HMI)',
      potentialCause: 'Potensi Penyebab: Terjadi error pada controller.',
      solution: 'Solusi: Matikan skuter, tunggu 5 menit, nyalakan skuter.',
      isOpened: false,
    },
    {
      title: 'Koneksi IoT',
      problem: 'ERROR: Controller Overheat (muncul text pada Cluster Display/HMI)',
      potentialCause: 'Potensi Penyebab: Suhu controller terlalu panas/tinggi.',
      solution: 'Solusi: Berhenti dan matikan skuter untuk mendinginkan selama 10 menit, nyalakan skuter.',
      isOpened: false,
    },
  ],
  portableCharger: [
    {
      title: 'Portable Charger',
      problem: 'Charger tidak dapat mengisi baterai',
      potentialCause: [
        'Tidak ada tegangan pada stop kontak sumber listrik.',
        'Charger dalam kondisi rusak.',
        'Baterai terlalu panas.',
      ],
      solution: [
        'Solusi: Cek tegangan stop kontak sumber listrik.',
        'Solusi: bawa charger ke Layanan Purna Jual',
        'Solusi: Diamkan baterai sampai dingin.',
      ],
      isOpened: false,
    },
    {
      title: 'Portable Charger Title',
      problem: 'Communication Failure:\nMerah-merah-hijau',
      potentialCause:
        'Potensi Penyebab: Ada masalah dalam proses komunikasi antara charger dan perangkat yang diisi daya.',
      solution:
        'Solusi: Pastikan memenuhi prosedur pengisian daya. Coba cabut dan koneksikan kembali charger dan motor listrik serta stop kontak.',
      isOpened: false,
    },
    {
      title: 'Portable Charger',
      problem: 'Input Exception:\nMerah-hijau-hijau',
      potentialCause:
        'Potensi Penyebab: Kesalahan atau masalah yang terjadi saat charger menerima input dari sumber daya eksternal, seperti stopkontak.',
      solution:
        'Solusi: Pastikan sumber listrik rumah memenuhi standard (± 220 vac, 50 Hz). Jika sudah sesuai, ulangi prosedur pengisian daya.',
      isOpened: false,
    },
    {
      title: 'Portable Charger',
      problem: 'Output Overvoltage:\nMerah-hijau-hijau-hijau',
      potentialCause:
        'Potensi Penyebab: Charger mengeluarkan tegangan yang melebihi batas aman yang telah ditentukan untuk baterai atau sistem motor listrik.',
      solution: 'Solusi: Ulangi prosedur pengisian daya.',
      isOpened: false,
    },
    {
      title: 'Portable Charger',
      problem: 'Superheating:\nMerah-merah-hijau-hijau',
      potentialCause: 'Potensi Penyebab: Suhu charger terlalu panas/tinggi.',
      solution:
        'Solusi: Charger akan berhenti sesaat. Namun, jika suhu sudah normal, proses pengisian daya akan otomatis memulai ulang.',
      isOpened: false,
    },
  ],
};

export default function Troubleshoot({ lang }: TroubleshootProps) {
  const [troubleshoots, setTroubleshoots] = useState<TroubleshootType>(
    lang === 'id' ? idTroubleshoots : enTroubleshoots
  );
  const [title, setTitle] = useState(false);
  const [tablist, setTablist] = useState<TroubleshootKey[]>([]);
  const [selectedTablistIndex, setSelectedTablistIndex] = useState(0);

  const onClickOpen = (key: keyof TroubleshootType, index: number) => {
    setTroubleshoots({
      ...troubleshoots,
      [key]: troubleshoots[key].map((item, idx) => (idx === index ? { ...item, isOpened: !item.isOpened } : item)),
    });
  };

  const onClickCapsule = (index: number) => {
    setSelectedTablistIndex(index);
  };

  useEffect(() => {
    const keys: TroubleshootKey[] = Object.keys(troubleshoots) as TroubleshootKey[];
    setTablist(keys);
  }, [troubleshoots]);

  return (
    <main className="troubleshoot-page">
      <div className="tab-bar">
        {tablist?.map((name: TroubleshootKey, index: number) => (
          <div
            key={index}
            className={`capsule ${selectedTablistIndex === index ? 'selected' : ''}`}
            onClick={() => onClickCapsule(index)}
          >
            {lang === 'en' ? enTroubleshoots[name][0].title : idTroubleshoots[name][0].title}
          </div>
        ))}
      </div>

      {troubleshoots[tablist[selectedTablistIndex]]?.map((item, index) => {
        return (
          <section className="troubleshoot-section" key={index}>
            {item.title === 'Portable Charger Title' && (
              <div className="title">Warna Indikator LED Portable Charger</div>
            )}
            <div className="troubleshoot-item">
              <div className="upper-content" onClick={() => onClickOpen(tablist[selectedTablistIndex], index)}>
                <div className="subtitle">{item.problem}</div>
                <div className={'icon' + (item.isOpened ? ' icon-open' : '')}>
                  <FontAwesomeIcon icon={faAngleDown} />
                </div>
              </div>
              <Collapse in={item.isOpened}>
                <div>
                  {typeof item?.potentialCause === 'string' && typeof item?.solution === 'string' && (
                    <>
                      <p className="paragraph">{item?.potentialCause}</p>
                      <p className="paragraph">{item?.solution}</p>
                    </>
                  )}
                  {typeof item?.potentialCause === 'object' && typeof item?.solution === 'object' && (
                    <>
                      <p className="paragraph">{lang === 'en' ? 'Potential Cause:' : 'Potensi Penyebab:'}</p>
                      {item?.potentialCause?.map((element, index) => (
                        <React.Fragment key={index}>
                          <div className="potcause-container">
                            <div className="left-content">
                              <p className="paragraph">{String.fromCharCode(97 + index) + ')'}</p>
                            </div>
                            <p className="paragraph">{element}</p>
                          </div>
                          <div className="potcause-container">
                            <div className="left-content"></div>
                            <p className="paragraph">{item?.solution[index]}</p>
                          </div>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </div>
              </Collapse>
              <hr style={{ opacity: 0.1 }} />
            </div>
          </section>
        );
      })}
    </main>
  );
}

import { Outlet, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import FAQPage from 'src/pages/FAQ';
import PrivacyAndPolicyPage from 'src/pages/PnP';
import TOUPage from 'src/pages/TOU';
import Troubleshoot from 'src/pages/Troubleshoot';
import DashboardPage from 'src/pages/dashboard';

export default function Router() {
  let routes: RouteObject[] = [
    {
      path: '/',
      element: <Outlet />,
      children: [
        { index: true, element: <DashboardPage /> },
        {
          path: 'dashboard',
          element: <DashboardPage />,
        },
        {
          path: 'en',
          children: [
            {
              path: 'faq',
              element: <FAQPage lang="en" />,
            },
            {
              path: 'troubleshoot',
              element: <Troubleshoot lang="en" />,
            },
            {
              path: 'terms_n_conditions',
              element: <TOUPage lang="en" />,
            },
            {
              path: 'privacy_policy',
              element: <PrivacyAndPolicyPage lang="en" />,
            },
          ],
        },
        {
          path: 'id',
          children: [
            {
              path: 'faq',
              element: <FAQPage lang="id" />,
            },
            {
              path: 'troubleshoot',
              element: <Troubleshoot lang="id" />,
            },
            {
              path: 'terms_n_conditions',
              element: <TOUPage lang="id" />,
            },
            {
              path: 'privacy_policy',
              element: <PrivacyAndPolicyPage lang="id" />,
            },
          ],
        },
      ],
    },
    // { path: "*", element: <AboutPage /> },
  ];

  return <RouterProvider router={createBrowserRouter(routes, { basename: '/' })} />;
}

import React from 'react';
import './TOU-styles.scss';

const enTouList: {
  title: string | JSX.Element;
  content: JSX.Element;
}[] = [
  {
    title: 'Terms and Conditions',
    content: (
      <>
        SAVART maintains the right, but has no obligation, to add, change, edit, amend, revise, modify, or remove parts
        of the Terms of Use without prior notice. The updated version of the Terms of Use will be effective on the dated
        of posting unless indicated otherwise in such Terms of Use. You should review the Terms of Use frequently
        because if you continue to use the Site and apps after the posting of such updated version of the Terms of Use,
        it will be assumed that you agree to and accept the changes. As a condition to SAVART's grant to you a
        non-exclusive, freely revocable, non-transferable, personal and limited privilege to access and use the Site and
        the Content, you need to comply with the Terms of Use at all times.
        <br />
        The Site and the Content may only be used by you for the reasons specifically permitted by the Terms of Use and
        the Site. You should never (i) post pornographic, nude, sexually suggestive, violent, racially offensive,
        hateful, or infringing content to the website, (ii) select, solicit, or otherwise collect user credentials from
        other users, (iii) probe or test the vulnerability of the website and app or any network connected to the
        website and app, (iv) breach the security or authentication measures on the website and apps or any network
        connected to the website and apps or use for any illegal or unauthorized purposes
      </>
    ),
  },
  {
    title: 'Content',
    content: (
      <>
        This apps and website's copyright and other intellectual property rights apply to all text, graphics,
        animations, videos, music, and other content. Without SAVART's express consent, these materials may not be
        copied, distributed, changed, or reposted to other websites. SAVART disclaims all liability and responsibility
        for any mistakes or omissions in the contents of this website. If there are any damages, whether direct or
        indirect, SAVART and any other parties engaged in making and distributing this website are not responsible.
        SAVART declines to offer any kind of compensation to persons that feel wronged by the usage of this website. Due
        to your access or use of the SAVART site or your download of any materials, data, text, and/or information from
        the site, SAVART is not liable for any damage to your computer equipment, software, or other property. <br />
        Without SAVART's prior written consent, you must comply with the following terms and conditions when using the
        Content: (i) you must not remove any proprietary notice language from any copies of the Content, (ii) will only
        use the information for your own personal, non-commercial informational needs and you must not copy or post the
        Content on any networked computer, (iii) you must make no modifications to any such content and broadcast the
        Content in any media, and (iv) will not make any additional representations or warranties The information on
        this website is provided for the convenience of SAVART's customers. If there are any inconsistencies or
        variations between what is mentioned there and what customers really experience, SAVART declines to accept
        responsibility for any inconsistencies or differences. SAVART has the right to alter any and all information
        specified on the website, including without limitation information about Dealers, Price Lists, Promotions,
        Maintenance Costs, Genuine Parts, Insurance, and other information, without giving prior notice to anybody. that
        the models that are readily accessible locally could not be the same as the vehicles shown and the exact specs
        on this website. The body and accessory colors of the car might differ somewhat from those shown on this
        website. The consistency of scooter costs between the Jakarta Province and other provinces is not SAVART's
        responsibility.
      </>
    ),
  },
  {
    title: "Third Party's Links",
    content: (
      <>
        It's possible for the Site and apps to link to other independent third-party websites or to let you link to
        other online resources (collectively, "Third Party's Links"). The presence of any Third Party's Links does not
        imply SAVART's endorsement of or affiliation with the operators or owners of such Third Party Web Sites; rather,
        it is offered to you as a convenience only. You acknowledge that SAVART is not responsible for the accuracy,
        appropriateness, legality, or any other aspect of third party links or their content and SAVART has no control
        over third party links. SAVART is also not responsible or liable, directly or indirectly, for any loss or damage
        resulting from the use of or reliance on any third party links or their content. You acknowledge that any
        information you obtain via the Third Party's Links is neither provided by nor supported by SAVART and that you
        must use your own independent judgment in deciding how to access and interact with them.
      </>
    ),
  },
  {
    title: 'Disclaimer',
    content: (
      <>
        The site, App and the Content are delivered without warranties of any kind, including but not limited to any
        warranties of title, accuracy, non-infringement, merchantability, or fitness for a specific purpose. SAVART
        makes no guarantees that your use of or access to the site and apps or the Content will lead to a particular
        outcome that satisfies your needs. This includes guarantees regarding the site's security, error-free operation,
        availability at specific times or locations, or the correction of any defects or other issues. Without prior
        warning, any information on the website and apps is subject to change. The content that you receive or download
        from the site and apps is not guaranteed to be free of viruses or other harmful elements or destructive
        characteristics by SAVART or by SAVART's directors, employees, agents, suppliers, partners, or content
        providers. For any acts, omissions, failures of performance, mistakes, interruptions, defects, delays in
        operation or transmission, computer malware, and unauthorized third-party access in connection with or with
        regard to your use of or access to the site apps, the Content, or the third parties' links, SAVART expressly
        disclaims any and all damage, liability, or injury. The site or apps, the Content, and the websites of third
        parties are all subject to your use or access at your own risk. The only recourse you have against SAVART if
        you're unhappy with the website or its content is to cease using it. SAVART and everyone associated with SAVART
        make no guarantees or claims about the site's content.
      </>
    ),
  },
  {
    title: 'Limitation of Liability',
    content: (
      <>
        Except where prohibited by law, SAVART will never be responsible for compensatory, direct, indirect, incidental,
        or punitive damages to you, including but not limited to lost profits, even if SAVART has been informed of the
        possibility of any such losses. <br />
        In spite of any provisions to the contrary in the Terms of Use, if SAVART is held responsible by a court or
        other tribunal of competent jurisdiction for any liability, damage, or loss related to or involving your use of
        or access to the site, the content, the third parties' links, or the Terms of Use, SAVART's liability will never
        in any case exceed US$10.00. The aforementioned restriction on incidental damages will not be expanded or
        otherwise altered by any concurrent claim or lawsuit arising out of or connected to your use or access of the
        website, the content, the Third Party Links, or the Terms of Use.
      </>
    ),
  },
  {
    title: 'Indemnity',
    content: (
      <>
        You acknowledge that by accessing or using the site or its content in violation of the terms of use, you are
        agreeing to defend, indemnify, and hold harmless SAVART and SAVART's officers, employees, contractors,
        directors, suppliers, shareholders, predecessors, successors in interest, and representatives from any demands,
        liabilities, loss, claims, and expenses, including reasonable legal fees. When you stop using the site or the
        content, this indemnity will continue to apply.
      </>
    ),
  },
  {
    title: 'Governing Law and Jurisdiction',
    content: (
      <>
        You consent to the Republic of Indonesian law, without regard to its rules on conflicts of law, governing all
        aspects pertaining to the Terms of Use, your access to or use of the Site or the Content, as well as any
        disputes. You and SAVART will make a good faith effort to settle any issue or dispute that may arise over the
        Terms of Use, your use of the Site or the Content, or your access to it. In the event that a settlement cannot
        be reached in sixty (60) days, either you or SAVART may request that the matter be mediated in the Republic of
        Indonesia. <br />
        If mediation cannot resolve the issue, either you or SAVART are free to seek any legal rights or remedies that
        may be available. For any and all disputes, claims, and legal proceedings arising out of or related to the Terms
        of Use, your access to or use of the Site or the Content, you consent to the exclusive jurisdiction and venue of
        Republic Indonesia.
      </>
    ),
  },
  {
    title: 'Miscellaneous',
    content: (
      <>
        You shouldn't give SAVART or anybody at SAVART any unsolicited ideas because SAVART or anyone at SAVART won't
        accept or take into account any unsolicited ideas, including without limitation, suggestions for brand-new
        product designs, technologies, processes, materials, or marketing strategies. Despite SAVART's request that you
        not send any unsolicited ideas to SAVART or anyone at SAVART, you hereby agree to assign to SAVART any
        unsolicited ideas you send to SAVART or anyone at SAVART, without any compensation to you, in order to avoid any
        potential or potential dispute or misunderstanding if SAVART's products or marketing plans happen to be similar
        to any unsolicited ideas. Any unsolicited ideas you provide to SAVART or anybody at SAVART will not be treated
        as secret or proprietary, and SAVART is free to use or disseminate the ideas and their contents anyway it sees
        fit. <br />
        The Terms of Use replace all prior or contemporaneous communications of any kind (whether oral, written, or
        electronic) between you and SAVART with regard to your access to and use of the Site and the Content and
        constitute the entire agreement between you and SAVART with respect to such access and use. The remainder of the
        Terms of Use shall remain in full force and effect in the event that any provision of the Terms of Use is
        determined by a court or other tribunal of competent jurisdiction to be invalid, void, or unenforceable and
        shall be replaced with a valid provision that most closely matches the intent of the original provision.
        <br />
        You will not use or export anything from the site, the content, or any copy or modification of that content in
        contravention of any laws or regulations that may be in force, including without limitation the export laws and
        regulations of the Republic of Indonesia.
        <br />
        Unless otherwise specified in the Terms of Service, SAVART may deliver all notifications (including legal
        process) that SAVART is obligated to deliver to you by any means permitted by law, including without limitation
        by posting the notice on the Site or by sending it to any email or postal address that you designate to SAVART.
        You agree that it is your duty to maintain your email and mailing addresses up to date and to regularly check
        the Site for alerts placed there.
        <br />
        The failure of SAVART to insist on strict observance of or enforce any part of the Terms of Use shall not be
        interpreted as or constitute a waiver by SAVART of our right to subsequently enforce that or any other provision
        of the Terms of Use. No activity between you or any other party and SAVART shall be considered to modify or
        supersede any section of the Terms of Use. Any waiver of compliance by SAVART in a particular situation does not
        imply that we will do so in other situations. Any waiver of compliance with the Terms of Use must be made in
        writing and given through one of our authorized representatives in order for it to be effective. Any rights or
        remedies granted to third parties by the Terms of Use are expressly disclaimed.
      </>
    ),
  },
  {
    title: 'Feedback',
    content: (
      <>
        You may reach us at info@savart-ev.com if you have any feedback or queries about the Terms of Use. Any
        suggestions or data you offer SAVART will be regarded as non-confidential.
      </>
    ),
  },
];

const idTouList: {
  title: string | JSX.Element;
  content: JSX.Element;
}[] = [
  {
    title: 'Syarat dan Ketentuan',
    content: (
      <>
        SAVART berhak, namun tidak berkewajiban, untuk menambah, mengubah, mengedit, merevisi, memodifikasi, atau
        menghapus bagian dari Syarat Penggunaan tanpa pemberitahuan sebelumnya. Versi terbaru dari Syarat Penggunaan
        akan berlaku pada tanggal posting kecuali dinyatakan lain dalam Syarat Penggunaan tersebut. Anda harus sering
        meninjau Syarat Penggunaan karena jika Anda terus menggunakan Situs dan aplikasi setelah posting versi terbaru
        dari Syarat Penggunaan, maka dianggap bahwa Anda setuju dan menerima perubahan tersebut. Sebagai syarat untuk
        pemberian hak non-eksklusif, dapat dicabut sewaktu-waktu, tidak dapat dipindahtangankan, pribadi, dan terbatas
        kepada Anda untuk mengakses dan menggunakan Situs dan Konten, Anda perlu mematuhi Syarat Penggunaan setiap saat.
        <br />
        Situs dan Konten hanya dapat digunakan oleh Anda untuk alasan yang secara khusus diizinkan oleh Syarat
        Penggunaan dan Situs. Anda tidak boleh (i) memposting konten pornografi, telanjang, sugestif seksual, kekerasan,
        rasis, penuh kebencian, atau melanggar ke situs web, (ii) memilih, meminta, atau mengumpulkan kredensial
        pengguna dari pengguna lain, (iii) menguji atau memeriksa kerentanan situs web dan aplikasi atau jaringan yang
        terhubung ke situs web dan aplikasi, (iv) melanggar keamanan atau langkah-langkah otentikasi di situs web dan
        aplikasi atau jaringan yang terhubung ke situs web dan aplikasi atau menggunakannya untuk tujuan ilegal atau
        tidak sah.
      </>
    ),
  },
  {
    title: 'Konten',
    content: (
      <>
        Hak cipta dan hak kekayaan intelektual lainnya pada aplikasi dan situs web ini berlaku untuk semua teks, grafik,
        animasi, video, musik, dan konten lainnya. Tanpa persetujuan tertulis dari SAVART, materi-materi ini tidak boleh
        disalin, didistribusikan, diubah, atau diposting ulang ke situs web lain. SAVART menolak semua tanggung jawab
        dan kewajiban atas kesalahan atau kelalaian dalam isi situs web ini. Jika ada kerusakan, baik langsung atau
        tidak langsung, SAVART dan pihak lain yang terlibat dalam pembuatan dan distribusi situs web ini tidak
        bertanggung jawab. SAVART tidak menawarkan kompensasi dalam bentuk apapun kepada orang yang merasa dirugikan
        oleh penggunaan situs web ini. Karena akses atau penggunaan situs SAVART atau pengunduhan materi, data, teks,
        dan/atau informasi dari situs ini, SAVART tidak bertanggung jawab atas kerusakan pada peralatan komputer,
        perangkat lunak, atau properti lainnya.
        <br />
        Tanpa persetujuan tertulis sebelumnya dari SAVART, Anda harus mematuhi ketentuan dan syarat berikut saat
        menggunakan Konten: (i) Anda tidak boleh menghapus bahasa pemberitahuan hak milik dari salinan Konten manapun,
        (ii) hanya akan menggunakan informasi untuk kebutuhan informasi pribadi Anda sendiri dan tidak boleh menyalin
        atau memposting Konten di komputer jaringan manapun, (iii) tidak boleh membuat modifikasi pada konten apapun dan
        menyiarkan Konten di media apapun, dan (v) tidak akan membuat pernyataan atau jaminan tambahan.
        <br />
        Informasi di situs web ini disediakan untuk kenyamanan pelanggan SAVART. Jika ada ketidakkonsistenan atau
        variasi antara apa yang disebutkan di sana dan apa yang sebenarnya dialami pelanggan, SAVART menolak untuk
        menerima tanggung jawab atas ketidakkonsistenan atau perbedaan tersebut. SAVART berhak untuk mengubah informasi
        apapun dan semua yang ditentukan di situs web, termasuk namun tidak terbatas pada informasi tentang Dealer,
        Daftar Harga, Promosi, Biaya Perawatan, Suku Cadang Asli, Asuransi, dan informasi lainnya, tanpa memberikan
        pemberitahuan sebelumnya kepada siapapun. Model yang tersedia secara lokal mungkin tidak sama dengan kendaraan
        yang ditunjukkan dan spesifikasi yang tepat di situs web ini. Warna bodi dan aksesori mobil mungkin sedikit
        berbeda dari yang ditunjukkan di situs web ini. Konsistensi harga skuter antara Provinsi Jakarta dan provinsi
        lainnya bukan tanggung jawab SAVART.
      </>
    ),
  },
  {
    title: 'Tautan Pihak Ketiga',
    content: (
      <>
        Situs dan aplikasi mungkin memiliki tautan ke situs web pihak ketiga yang independen atau memungkinkan Anda
        untuk menautkan ke sumber daya online lainnya (secara kolektif disebut "Tautan Pihak Ketiga"). Keberadaan Tautan
        Pihak Ketiga tidak menunjukkan dukungan atau afiliasi SAVART dengan operator atau pemilik situs web pihak ketiga
        tersebut; melainkan, itu disediakan untuk kenyamanan Anda saja.
        <br />
        Anda mengakui bahwa SAVART tidak bertanggung jawab atas keakuratan, kesesuaian, legalitas, atau aspek lain dari
        tautan pihak ketiga atau kontennya dan SAVART tidak memiliki kendali atas tautan pihak ketiga. SAVART juga tidak
        bertanggung jawab secara langsung maupun tidak langsung atas kerugian atau kerusakan yang diakibatkan oleh
        penggunaan atau ketergantungan pada tautan pihak ketiga atau kontennya. Anda mengakui bahwa informasi yang Anda
        peroleh melalui Tautan Pihak Ketiga tidak disediakan oleh atau didukung oleh SAVART dan bahwa Anda harus
        menggunakan penilaian independen Anda sendiri dalam memutuskan bagaimana mengakses dan berinteraksi dengan
        mereka.
      </>
    ),
  },
  {
    title: <i>Disclaimer</i>,
    content: (
      <>
        Situs, aplikasi, dan Konten disampaikan tanpa jaminan apa pun, termasuk namun tidak terbatas pada jaminan judul,
        akurasi, non-pelanggaran, kelayakan jual, atau kesesuaian untuk tujuan tertentu. SAVART tidak menjamin bahwa
        penggunaan atau akses Anda ke situs dan aplikasi atau Konten akan menghasilkan hasil tertentu yang memenuhi
        kebutuhan Anda. Ini termasuk jaminan mengenai keamanan situs, operasi bebas kesalahan, ketersediaan pada waktu
        atau lokasi tertentu, atau perbaikan cacat atau masalah lainnya. Tanpa peringatan sebelumnya, informasi apa pun
        di situs web dan aplikasi dapat berubah. Konten yang Anda terima atau unduh dari situs dan aplikasi tidak
        dijamin bebas dari virus atau elemen berbahaya lainnya atau karakteristik destruktif oleh SAVART atau oleh
        direktur, karyawan, agen, pemasok, mitra, atau penyedia konten SAVART. Untuk setiap tindakan, kelalaian,
        kegagalan kinerja, kesalahan, gangguan, cacat, keterlambatan operasi atau transmisi, malware komputer, dan akses
        tidak sah pihak ketiga sehubungan dengan atau terkait dengan penggunaan atau akses Anda ke situs aplikasi,
        Konten, atau tautan pihak ketiga, SAVART dengan tegas menolak segala kerusakan, tanggung jawab, atau cedera.
        Penggunaan atau akses Anda ke situs atau aplikasi, Konten, dan situs web pihak ketiga adalah risiko Anda
        sendiri. Satu-satunya jalan yang Anda miliki terhadap SAVART jika Anda tidak puas dengan situs atau kontennya
        adalah berhenti menggunakannya. SAVART dan semua yang terkait dengan SAVART tidak memberikan jaminan atau klaim
        apa pun tentang konten situs.
      </>
    ),
  },
  {
    title: 'Batasan Tanggung Jawab',
    content: (
      <>
        Kecuali jika dilarang oleh hukum, SAVART tidak akan pernah bertanggung jawab atas kompensasi, kerugian langsung,
        tidak langsung, insidental, atau hukuman kepada Anda, termasuk namun tidak terbatas pada kehilangan keuntungan,
        bahkan jika SAVART telah diberitahu tentang kemungkinan kerugian tersebut.
        <br />
        Terlepas dari ketentuan apa pun dalam Syarat Penggunaan, jika SAVART dianggap bertanggung jawab oleh pengadilan
        atau lembaga lain yang memiliki yurisdiksi atas setiap kewajiban, kerusakan, atau kerugian yang terkait dengan
        atau melibatkan penggunaan atau akses Anda ke situs, konten, tautan pihak ketiga, atau Syarat Penggunaan,
        tanggung jawab SAVART tidak akan pernah melebihi US$10.00. Pembatasan atas kerusakan insidental yang disebutkan
        di atas tidak akan diperluas atau diubah dengan klaim atau gugatan bersamaan yang timbul dari atau terkait
        dengan penggunaan atau akses Anda ke situs, konten, Tautan Pihak Ketiga, atau Syarat Penggunaan.
      </>
    ),
  },
  {
    title: 'Ganti Rugi',
    content: (
      <>
        Anda mengakui bahwa dengan mengakses atau menggunakan situs atau kontennya yang melanggar syarat penggunaan,
        Anda setuju untuk membela, mengganti kerugian, dan membebaskan SAVART dan pejabat, karyawan, kontraktor,
        direktur, pemasok, pemegang saham, pendahulu, penerus, dan perwakilan SAVART dari tuntutan, kewajiban, kerugian,
        klaim, dan pengeluaran, termasuk biaya hukum yang wajar. Ketika Anda berhenti menggunakan situs atau konten,
        ketentuan ganti rugi ini akan tetap berlaku.
      </>
    ),
  },
  {
    title: 'Hukum dan Yurdikasi yang Berlaku',
    content: (
      <>
        Anda menyetujui bahwa hukum Republik Indonesia, tanpa memperhatikan aturan konflik hukumnya, akan mengatur semua
        aspek yang terkait dengan Syarat Penggunaan, akses atau penggunaan Anda atas Situs atau Konten, serta setiap
        sengketa. Anda dan SAVART akan berusaha dengan itikad baik untuk menyelesaikan setiap masalah atau sengketa yang
        mungkin timbul atas Syarat Penggunaan, penggunaan Anda atas Situs atau Konten, atau akses Anda ke dalamnya. Jika
        penyelesaian tidak dapat dicapai dalam enam puluh (60) hari, baik Anda maupun SAVART dapat meminta agar masalah
        tersebut dimediasi di Republik Indonesia.
        <br />
        Jika mediasi tidak dapat menyelesaikan masalah, baik Anda maupun SAVART bebas untuk mencari hak atau upaya hukum
        yang mungkin tersedia. Untuk setiap dan semua sengketa, klaim, dan proses hukum yang timbul dari atau terkait
        dengan Syarat Penggunaan, akses atau penggunaan Anda atas Situs atau Konten, Anda menyetujui yurisdiksi dan
        tempat eksklusif di Republik Indonesia.
      </>
    ),
  },
  {
    title: 'Lain-Lain',
    content: (
      <>
        Anda tidak seharusnya memberikan ide-ide yang tidak diminta kepada SAVART atau siapa pun di SAVART karena SAVART
        atau siapa pun di SAVART tidak akan menerima atau mempertimbangkan ide-ide yang tidak diminta, termasuk namun
        tidak terbatas pada, saran untuk desain produk baru, teknologi, proses, bahan, atau strategi pemasaran. Meskipun
        SAVART meminta Anda untuk tidak mengirim ide-ide yang tidak diminta kepada SAVART atau siapa pun di SAVART, Anda
        dengan ini setuju untuk mengalihkan kepada SAVART setiap ide yang tidak diminta yang Anda kirimkan kepada SAVART
        atau siapa pun di SAVART, tanpa kompensasi apa pun kepada Anda, untuk menghindari setiap potensi sengketa atau
        kesalahpahaman jika produk atau rencana pemasaran SAVART kebetulan mirip dengan ide-ide yang tidak diminta.
        Setiap ide yang tidak diminta yang Anda berikan kepada SAVART atau siapa pun di SAVART tidak akan dianggap
        rahasia atau hak milik, dan SAVART bebas menggunakan atau menyebarkan ide-ide tersebut dan isinya dengan cara
        apa pun yang diinginkan.
        <br />
        Syarat Penggunaan menggantikan semua komunikasi sebelumnya atau kontemporer dalam bentuk apa pun (baik lisan,
        tertulis, atau elektronik) antara Anda dan SAVART terkait dengan akses dan penggunaan Anda atas Situs dan Konten
        dan merupakan kesepakatan penuh antara Anda dan SAVART terkait dengan akses dan penggunaan tersebut. Bagian lain
        dari Syarat Penggunaan akan tetap berlaku sepenuhnya jika ketentuan apa pun dari Syarat Penggunaan dinyatakan
        oleh pengadilan atau pengadilan lain yang memiliki yurisdiksi sebagai tidak sah, batal, atau tidak dapat
        dilaksanakan dan akan digantikan dengan ketentuan yang sah yang paling sesuai dengan maksud ketentuan asli.
        <br />
        Anda tidak akan menggunakan atau mengekspor apa pun dari situs, konten, atau salinan atau modifikasi konten
        tersebut yang bertentangan dengan hukum atau peraturan yang berlaku, termasuk namun tidak terbatas pada
        undang-undang dan peraturan ekspor Republik Indonesia.
        <br />
        Kecuali jika ditentukan lain dalam Ketentuan Layanan, SAVART dapat menyampaikan semua pemberitahuan (termasuk
        proses hukum) yang wajib disampaikan kepada Anda dengan cara apa pun yang diizinkan oleh hukum, termasuk namun
        tidak terbatas pada dengan memposting pemberitahuan di Situs atau mengirimkannya ke alamat email atau pos yang
        Anda tunjukkan kepada SAVART. Anda setuju bahwa adalah tanggung jawab Anda untuk menjaga alamat email dan pos
        Anda tetap terbaru dan untuk secara teratur memeriksa Situs untuk pemberitahuan yang dipasang di sana.
        <br />
        Kegagalan SAVART untuk menuntut ketaatan yang ketat atau menegakkan bagian apa pun dari Syarat Penggunaan tidak
        akan ditafsirkan sebagai atau merupakan pengabaian oleh SAVART atas hak kami untuk kemudian menegakkan ketentuan
        tersebut atau ketentuan lain dari Syarat Penggunaan. Tidak ada aktivitas antara Anda atau pihak lain dengan
        SAVART yang akan dianggap mengubah atau menggantikan bagian apa pun dari Syarat Penggunaan. Setiap pengabaian
        kepatuhan oleh SAVART dalam situasi tertentu tidak menyiratkan bahwa kami akan melakukannya dalam situasi lain.
        Setiap pengabaian kepatuhan terhadap Syarat Penggunaan harus dilakukan secara tertulis dan diberikan melalui
        salah satu perwakilan resmi kami agar efektif. Setiap hak atau upaya hukum yang diberikan kepada pihak ketiga
        oleh Syarat Penggunaan secara tegas ditolak.
      </>
    ),
  },
  {
    title: 'Umpan Balik',
    content: (
      <>
        Anda dapat menghubungi kami di info@savart-ev.com jika Anda memiliki umpan balik atau pertanyaan tentang Syarat
        Penggunaan. Setiap saran atau data yang Anda tawarkan kepada SAVART akan dianggap tidak rahasia.
      </>
    ),
  },
];

interface TOUPageState {
  openedAnswers: boolean[];
  lang: 'en' | 'id';
}

interface TOUPageProps {
  lang?: 'en' | 'id';
}

export default class TOUPage extends React.Component<TOUPageProps, TOUPageState> {
  openedAnswers: boolean[] = [];

  constructor(props: TOUPageProps) {
    super(props);

    for (let i = 0; i < enTouList.length; i++) {
      this.openedAnswers.push(false);
    }

    this.state = {
      lang: this.props.lang || 'en',
      openedAnswers: this.openedAnswers,
    };
  }

  openAnswer = (idx: number) => {
    this.openedAnswers[idx] = !this.openedAnswers[idx];
    this.setState({ openedAnswers: this.openedAnswers });
  };

  render() {
    let faqListElm: JSX.Element[] = [];
    (this.state.lang === 'en' ? enTouList : idTouList).forEach((val, idx) => {
      faqListElm.push(
        <li className="tou-list" key={idx}>
          <div className="tou-title">{val.title}</div>
          <div className="tou-content">{val.content}</div>
        </li>
      );
    });

    return (
      <div className="tou-page">
        <ol>{faqListElm}</ol>
      </div>
    );
  }
}

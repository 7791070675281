import React from 'react';
import { Collapse } from 'react-bootstrap';
import './FAQ-styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const enFaqList: {
  question: string;
  answer: JSX.Element;
}[] = [
  {
    question: 'What is the Savart S-1P Insignia Electric Scooter?',
    answer: (
      <div id="example-collapse-text">
        The Savart S-1P Insignia is an electric scooter made in Indonesia, designed for urban use with modern and
        eco-friendly features. This scooter is equipped with advanced technology and ergonomic design for comfort and
        efficiency.
      </div>
    ),
  },
  {
    question: 'What is the maximum speed of the Savart S-1P Insignia?',
    answer: (
      <div id="example-collapse-text">
        The maximum speed of the Savart S-1P Insignia is 120 km/h. This speed is adjusted for user safety.
      </div>
    ),
  },
  {
    question: 'What is the range of the Savart S-1P Insignia?',
    answer: (
      <div id="example-collapse-text">
        This electric scooter has a range of up to 120 km on a full charge, depending on road conditions and riding
        style.
      </div>
    ),
  },
  {
    question: 'Is this scooter waterproof?',
    answer: (
      <div id="example-collapse-text">
        Yes, the Savart S-1P Insignia has an IP 67 waterproof rating, allowing for use in rainy conditions.
      </div>
    ),
  },
  {
    question: 'Can this scooter be used on all types of roads?',
    answer: (
      <div id="example-collapse-text">
        The Savart S-1P Insignia is designed for urban use but can also be used for long distances.
      </div>
    ),
  },
  {
    question: 'Are there any special safety features?',
    answer: (
      <div id="example-collapse-text">
        Yes, this scooter is equipped with a CBS system, LED lights for nighttime visibility, and a remote lock system
        in case of theft or loss.
      </div>
    ),
  },
  {
    question: 'Can the speed of the Savart S-1P Insignia be adjusted?',
    answer: (
      <div id="example-collapse-text">
        Yes, we provide a mode feature to adjust your riding mode and select the speed according to your preference and
        riding conditions.
      </div>
    ),
  },
  {
    question: 'How long does it take to fully charge the battery?',
    answer: <div id="example-collapse-text">Charging the battery to full takes about 6-8 hours.</div>,
  },
  {
    question: 'Is the Savart S-1P Insignia equipped with an app?',
    answer: (
      <div id="example-collapse-text">
        Yes, the Savart S-1P Insignia is equipped with an app that helps you start the vehicle, monitor trips, battery
        usage, charging process, and many other features that make using the Savart S-1P Insignia easier. You can
        download the Savart app from the Google Play Store or Apple Store.
      </div>
    ),
  },
  {
    question: 'Where can the Savart S-1P Insignia be purchased?',
    answer: (
      <div id="example-collapse-text">
        You can order the Savart S-1P Insignia through Tokopedia or Blibli at Savart Motors Indonesia.
      </div>
    ),
  },
  {
    question: 'What warranty is offered by the Savart S-1P Insignia?',
    answer: (
      <div id="example-collapse-text">
        You can access the warranty details offered by Savart S-1P Insignia at savart-ev.com/warranty.
      </div>
    ),
  },
  {
    question: 'What should be done if there is a technical issue with the scooter?',
    answer: (
      <div id="example-collapse-text">
        If there is a technical issue, it is best to check the manual for troubleshooting, contact Savart Customer
        Service via the Savart app, or take it to the nearest Planet Ban.
      </div>
    ),
  },
  {
    question: 'Is after-sales service available?',
    answer: (
      <div id="example-collapse-text">
        Yes, Savart provides after-sales services including repairs, replacement parts, and technical assistance. You
        can go to the nearest Planet Ban. Savart collaborates with Planet Ban. Be sure to keep the proof of purchase and
        warranty information to receive these services.
      </div>
    ),
  },
];

const idFaqList: {
  question: string;
  answer: JSX.Element;
}[] = [
  {
    question: 'Apa itu Skuter Listrik Savart S-1P Insignia?',
    answer: (
      <div id="example-collapse-text">
        Savart S-1P Insignia adalah skuter listrik buatan Indonesia yang dirancang untuk penggunaan perkotaan dengan
        fitur-fitur modern dan ramah lingkungan. Skuter ini dilengkapi dengan teknologi canggih dan desain ergonomis
        untuk kenyamanan dan efisiensi.
      </div>
    ),
  },
  {
    question: 'Berapa kecepatan maksimal Savart S-1P Insignia?',
    answer: (
      <div id="example-collapse-text">
        Kecepatan maksimal Savart S-1P Insignia adalah 120 km/jam. Kecepatan ini disesuaikan untuk keamanan pengguna.
      </div>
    ),
  },
  {
    question: 'Berapa jarak tempuh dalam Savart S-1P Insignia?',
    answer: (
      <div id="example-collapse-text">
        Skuter listrik ini memiliki jarak tempuh hingga 120 km dalam sekali pengisian penuh, tergantung pada kondisi
        jalan dan gaya berkendara.
      </div>
    ),
  },
  {
    question: 'Apakah skuter ini tahan air?',
    answer: (
      <div id="example-collapse-text">
        Ya, Savart S-1P Insignia sudah IP 67 memiliki rating tahan air yang memungkinkan penggunaan dalam kondisi hujan.
      </div>
    ),
  },
  {
    question: 'Apakah skuter ini bisa digunakan di semua jenis jalan?',
    answer: (
      <div id="example-collapse-text">
        Savart S-1P Insignia dirancang untuk penggunaan dijalan perkotaan namun juga bisa digunakan untuk jarak jauh.
      </div>
    ),
  },
  {
    question: 'Apakah ada fitur keamanan khusus?',
    answer: (
      <div id="example-collapse-text">
        Ya, skuter ini dilengkapi dengan system CBS, lampu LED untuk visibilitas dimalam hari, lock skuter jarak jauh
        apabila terjadi pencurian/kehilangan skuter.
      </div>
    ),
  },
  {
    question: 'Apakah kecepatan Savart S-1P Insignia dapat disesuaikan?',
    answer: (
      <div id="example-collapse-text">
        Ya, kami menyediakan fitur mode untuk mengatur mode berkendara anda, serta kecepatan yang bisa anda pilih sesuai
        dengan prefrensi/kondisi berkendara Anda.
      </div>
    ),
  },
  {
    question: 'Berapa lama waktu yang diperlukan untuk mengisi penuh baterai',
    answer: <div id="example-collapse-text">Pengisian baterai hingga penuh memerlukan waktu sekitar 6-8 jam.</div>,
  },
  {
    question: 'Apakah Savart S-1P Insignia dilengkapi dengan Apps?',
    answer: (
      <div id="example-collapse-text">
        Ya, Savart S-1P Insignia dilengkapi oleh Apps yang dapat membantu anda untuk menyalakan kendaraan, monitoring
        perjalanan, penggunaan baterai, proses pengecasan, dan banyak fitur lainnya yang mempermudah penggunaan Savart
        S-1P Insignia. Anda dapat mendownload Savart Apps di Google Play Store maupun Apple Store.
      </div>
    ),
  },
  {
    question: 'Savart S-1P Insignia dapat dibeli dimana?',
    answer: (
      <div id="example-collapse-text">
        Anda dapat memesan Savart S-1P Insignia melalui Tokopedia/Blibli Savart Motors Indonesia.
      </div>
    ),
  },
  {
    question: 'Apa garansi yang ditawarkan oleh Savart S-1P Insignia?',
    answer: (
      <div id="example-collapse-text">
        Detail garansi yang ditawarkan Savart S-1P Insignia dapat anda akses pada savart-ev.com/warranty
      </div>
    ),
  },
  {
    question: 'Bagaimana jika terjadi masalah teknis dengan skuter?',
    answer: (
      <div id="example-collapse-text">
        Jika terjadi masalah teknis, sebaiknya cek buku panduan pada bagian cara mengatasi masalah tekni, hubungi
        Customer Service Savart pada Apps Savart atau bawa ke Planet Ban terdekat.
      </div>
    ),
  },
  {
    question: 'Apakah tersedia layanan purna jual?',
    answer: (
      <div id="example-collapse-text">
        Ya, Savart menyediakan layanan purna jual termasuk perbaikan, penggantian suku cadang, dan bantuan teknis. Anda
        dapat ke Planet Ban terdekat. Savart berkerjasama dengan Planet Ban. Pastikan untuk menyimpan bukti pembelian
        dan informasi garansi untuk mendapatkan layanan ini.
      </div>
    ),
  },
];

interface FAQPageProps {
  lang?: 'en' | 'id';
}

interface FAQPageState {
  lang: 'en' | 'id';
  openedAnswers: boolean[];
}

export default class FAQPage extends React.Component<FAQPageProps, FAQPageState> {
  openedAnswers: boolean[] = [];

  constructor(props: FAQPageProps) {
    super(props);

    for (let i = 0; i < enFaqList.length; i++) {
      this.openedAnswers.push(false);
    }

    this.state = {
      lang: this.props.lang || 'en',
      openedAnswers: this.openedAnswers,
    };
  }

  openAnswer = (idx: number) => {
    this.openedAnswers[idx] = !this.openedAnswers[idx];
    this.setState({ openedAnswers: this.openedAnswers });
  };

  render() {
    let faqListElm: JSX.Element[] = [];
    (this.state.lang === 'en' ? enFaqList : idFaqList).forEach((val, idx) => {
      faqListElm.push(
        <div className="faq-list" key={idx}>
          <div className="faq-question" onClick={() => this.openAnswer(idx)}>
            <div className="text">{val.question}</div>
            <div className={'icon' + (this.state.openedAnswers[idx] ? ' icon-open' : '')}>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <Collapse in={this.state.openedAnswers[idx]}>
            <div>
              <div className="faq-answer">{val.answer}</div>
            </div>
          </Collapse>
          <hr style={{ opacity: 0.1 }} />
        </div>
      );
    });

    return <div className="faq-page">{faqListElm}</div>;
  }
}

import React from 'react';
import './PnP-styles.scss';

const enPnpList: {
  title: string;
  content: JSX.Element;
}[] = [
  {
    title: 'Privacy and Policy',
    content: (
      <>
        By entering this Apps, you understand and accept all of the terms contained in this apps. Your privacy is
        something we value and are committed to safeguarding. You may read about how your personal data is handled and
        used in this policy. We pledge to use your personal information in line with the terms of this policy and will
        take the necessary precautions to do so.
      </>
    ),
  },
  {
    title: 'Collention & Use of Information',
    content: (
      <>
        All data gathered from SAVART-owned websites and applications belongs exclusively to SAVART. We won't disclose,
        sell, or rent your personal information to third parties in a way that is not specified in those terms and
        conditions. and we won't make changes without giving you notice.
      </>
    ),
  },
  {
    title: 'Security',
    content: (
      <>
        Your personal information will be maintained by SAVART and will be shielded from forgery, manipulation, and
        irresponsible access and disclosure.
      </>
    ),
  },
  {
    title: 'Cookies Policy',
    content: (
      <>
        Cookies are used by our website and application to gather data that will help for providing better service for
        you. Cookies are text files that keep information on your computer (including tablets and mobile phones).
        Cookies gather information about users and how you use the website or application, including your Internet
        Protocol (IP) address, how you found the website (for example, through a search engine or a link from another
        website), and how you moved about the website or application. Information from these cookies helps us collect
        visitor's statistical information for services in our website and application. In order to make our website and
        application more easy and helpful for you, some cookies are crucial for the effective operation of our website.
        When you shut your browser, some cookies will be transitory and vanish, while others will be persistent and stay
        on your computer for several times. You should be aware that certain features and sites won't operate as
        efficiently if you decide not to utilize cookies during your visit. Please refer to your browser's instructions
        by selecting 'Help' from the menu if you want to erase the cookies that are currently stored on your computer.
      </>
    ),
  },
  {
    title: 'Links to Other Website',
    content: (
      <>
        SAVART website and application may contain links to other websites that are not owned by SAVART. This privacy
        statement only applies to SAVART-owned websites and applications. We advise you to read and comprehend the
        privacy policies of any other websites you access via the SAVART website or application. You understand that
        SAVART has no control over third parties' links and that SAVART disclaims all liability for the accuracy,
        appropriateness, legality, or any other aspect of the third parties' links or content. SAVART is also not
        responsible or liable, directly or indirectly, for any loss or damage resulting from the use of or reliance on
        any information found on the third parties' links or related to such use or reliance.
      </>
    ),
  },
  {
    title: 'Changes of Statement',
    content: (
      <>
        SAVART has the right to modify every aspect of the information specifications provided on the site, including
        but not limited to information about Dealers, Price Lists, Promos, Maintenance Costs, Genuine Parts, Insurance,
        and other information without providing prior notice to anyone.
      </>
    ),
  },
];

const idPnpList: {
  title: string | JSX.Element;
  content: JSX.Element;
}[] = [
  {
    title: 'Privasi dan Kebijakan',
    content: (
      <>
        Dengan memasuki Aplikasi ini, Anda memahami dan menerima semua ketentuan yang terdapat dalam aplikasi ini.
        Privasi Anda adalah sesuatu yang kami hargai dan kami berkomitmen untuk melindunginya. Anda dapat membaca
        tentang bagaimana data pribadi Anda ditangani dan digunakan dalam kebijakan ini. Kami berjanji untuk menggunakan
        informasi pribadi Anda sesuai dengan ketentuan kebijakan ini dan akan mengambil tindakan pencegahan yang
        diperlukan untuk melakukannya.
      </>
    ),
  },
  {
    title: 'Pengumpulan dan Penggunaan Informasi',
    content: (
      <>
        Semua data yang dikumpulkan dari situs web dan aplikasi milik SAVART sepenuhnya milik SAVART. Kami tidak akan
        mengungkapkan, menjual, atau menyewakan informasi pribadi Anda kepada pihak ketiga dengan cara yang tidak
        ditentukan dalam syarat dan ketentuan tersebut. dan kami tidak akan membuat perubahan tanpa memberi Anda
        pemberitahuan.
      </>
    ),
  },
  {
    title: 'Keamanan',
    content: (
      <>
        Informasi pribadi Anda akan dikelola oleh SAVART dan akan dilindungi dari pemalsuan, manipulasi, dan akses serta
        pengungkapan yang tidak bertanggung jawab.
      </>
    ),
  },
  {
    title: (
      <>
        Kebijakan <i>Cookies</i>
      </>
    ),
    content: (
      <>
        Cookies digunakan oleh situs web dan aplikasi kami untuk mengumpulkan data yang akan membantu memberikan layanan
        yang lebih baik untuk Anda. Cookies adalah file teks yang menyimpan informasi di komputer Anda (termasuk tablet
        dan ponsel). Cookies mengumpulkan informasi tentang pengguna dan bagaimana Anda menggunakan situs web atau
        aplikasi, termasuk alamat Internet Protocol (IP) Anda, bagaimana Anda menemukan situs web (misalnya, melalui
        mesin pencari atau tautan dari situs web lain), dan bagaimana Anda berpindah di sekitar situs web atau aplikasi.
        Informasi dari cookies ini membantu kami mengumpulkan informasi statistik pengunjung untuk layanan di situs web
        dan aplikasi kami. Agar situs web dan aplikasi kami lebih mudah dan bermanfaat bagi Anda, beberapa cookies
        sangat penting untuk operasi yang efektif dari situs web kami. Ketika Anda menutup browser, beberapa cookies
        akan bersifat sementara dan hilang, sementara yang lain akan bersifat permanen dan tetap ada di komputer Anda
        selama beberapa waktu. Anda harus menyadari bahwa fitur dan situs tertentu tidak akan berfungsi seefisien jika
        Anda memutuskan untuk tidak menggunakan cookies selama kunjungan Anda. Silakan merujuk pada petunjuk browser
        Anda dengan memilih 'Bantuan' dari menu jika Anda ingin menghapus cookies yang saat ini disimpan di komputer
        Anda.
      </>
    ),
  },
  {
    title: 'Tautan ke Situs  Web Lain',
    content: (
      <>
        Situs web dan aplikasi SAVART mungkin berisi tautan ke situs web lain yang tidak dimiliki oleh SAVART.
        Pernyataan privasi ini hanya berlaku untuk situs web dan aplikasi yang dimiliki oleh SAVART. Kami menyarankan
        Anda untuk membaca dan memahami kebijakan privasi dari setiap situs web lain yang Anda akses melalui situs web
        atau aplikasi SAVART. Anda memahami bahwa SAVART tidak memiliki kendali atas tautan pihak ketiga dan bahwa
        SAVART tidak bertanggung jawab atas keakuratan, kesesuaian, legalitas, atau aspek lain dari tautan atau konten
        pihak ketiga. SAVART juga tidak bertanggung jawab secara langsung maupun tidak langsung atas kerugian atau
        kerusakan yang diakibatkan oleh penggunaan atau ketergantungan pada informasi yang ditemukan di tautan pihak
        ketiga atau terkait dengan penggunaan atau ketergantungan tersebut.
      </>
    ),
  },
  {
    title: 'Perubahan Pernyataan',
    content: (
      <>
        SAVART berhak untuk memodifikasi setiap aspek dari spesifikasi informasi yang disediakan di situs ini, termasuk
        namun tidak terbatas pada informasi tentang Dealer, Daftar Harga, Promosi, Biaya Perawatan, Suku Cadang Asli,
        Asuransi, dan informasi lainnya tanpa memberikan pemberitahuan sebelumnya kepada siapa pun.
      </>
    ),
  },
];

interface PrivacyAndPolicyPageState {
  openedAnswers: boolean[];
  lang: 'en' | 'id';
}

interface PrivacyAndPolicyPageProps {
  lang?: 'en' | 'id';
}

export default class PrivacyAndPolicyPage extends React.Component<
  PrivacyAndPolicyPageProps,
  PrivacyAndPolicyPageState
> {
  openedAnswers: boolean[] = [];

  constructor(props: PrivacyAndPolicyPageProps) {
    super(props);

    for (let i = 0; i < enPnpList.length; i++) {
      this.openedAnswers.push(false);
    }

    this.state = {
      lang: this.props.lang || 'en',
      openedAnswers: this.openedAnswers,
    };
  }

  openAnswer = (idx: number) => {
    this.openedAnswers[idx] = !this.openedAnswers[idx];
    this.setState({ openedAnswers: this.openedAnswers });
  };

  render() {
    let enPnpListElm: JSX.Element[] = [];
    (this.state.lang === 'en' ? enPnpList : idPnpList).forEach((val, idx) => {
      enPnpListElm.push(
        <div className="pnp-list" key={idx}>
          <div className="pnp-title">{val.title}</div>
          <div className="pnp-content">{val.content}</div>
        </div>
      );
    });

    return <div className="pnp-page">{enPnpListElm}</div>;
  }
}
